<template>
  <div class="page--mask-station login-failed">
    <div class="container-mask-station">
      <img src="/static/img/mask-station/logo.png" class="logo" />

      <img src="/static/img/mask-station/ooops-error.png" class="img-ooops" />

      <h2 class="heading">QR CODE NOT FOUND :(</h2>
      <p class="copywriting">
        Maaf, QR Code kamu tidak ditemukan.<br />Silakan ulangi lagi dan pastikan <br />
        kamu sudah punya akun SOCO ya!
      </p>

      <div class="group-btn">
        <router-link to="/mask-station/home" class="btn btn-dark btn-try-again">TRY AGAIN</router-link>
        <router-link to="/mask-station/home" class="btn btn-cancel">CANCEL</router-link>
      </div>
    </div>

    <div class="box">
      <p class="box__paraf">
        <img src="/static/img/mask-station/important.svg" class="box__icon" /> Jika Anda belum terdaftar sebagai user
        SOCO. Silakan mendaftar melalui LCD registration.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginFailed',
};
</script>
